import axios from 'axios';
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import hostlink from '../Hostlink/hostlink';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';

const BillPaymentRest = () => {
  const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
  const [TableListUnbilled, setTableListUnbilled] = useState([]);
  const [cmbAccdata, setcmbAccdata] = useState([]);
  const [selected, setSelected] = useState(0);
  const [Payselected, setPayselected] = useState(0);
  const handleChange = (index) => {
    setSelected(index);
    setPayselected(index);
    setMessagedata({ ...messagedata, payacccode: index})
  };
  const [messagedata, setMessagedata] = useState({
    payblno: "",
    paybltype: "",
    payblAmt: "",
    payacccode: "",
  })
  const { payacccode, payblno, paybltype, payblAmt } = messagedata;

  const paybill = async (e) => {
    await axios.post(`${hostlink}/RestbillPayment/${usershopid}/${payblno}/${payacccode}`).then((resp) => { console.log("PAID");toast.success("Paid Sucessfull") }).catch((resp) => { console.log("Something else") });
    setInterval(() => {
      window.location.assign("/BillPaymentRest")
    }, 1000);
  };

  const [isModalOpen_Payment, setIsModalOpen_Payment] = useState(false);
  const handleCloseModal_Payment = () => { setIsModalOpen_Payment(false); };
  const handleNoClick_Payment = () => { console.log('User clicked No'); handleCloseModal_Payment(); };
  useEffect(() => {
    axios.get(`${hostlink}/RestbillviewPayment/${usershopid}`).then((resp) => { setTableListUnbilled(resp.data) }).catch((resp) => { console.log("Not Fatch") });
    axios.get(`${hostlink}/${usershopid}/accmas`).then((Response) => { if (Response.data.length > 0) { setSelected(Response.data[0].id); } console.log(Response.data); setcmbAccdata(Response.data); }).catch((Response) => { "data Not Found" })
  }, [])

  return (
    <div style={{ "background": "linear-gradient(to bottom, #87CEEB, #ffffff)" }}>

      <div className='container' style={{ fontFamily: "Bahnschrift Condensed" }}>
        <div className="row" >
          <h4 className='text-center text-bg-dark rounded'><i class="fa-solid fa-indian-rupee-sign"></i> Payment</h4>
          {TableListUnbilled.map((x) => { return (<div className='col-md-3 p-1'><button onClick={() => { setMessagedata({ ...messagedata, payblno: x.SaleMasDTO.shopvno, paybltype: x.bltype, payblAmt: x.SaleMasDTO.totblamt }); setIsModalOpen_Payment(true) }} className={`btn shadow rounded ${x.bltype === 'Delivery' ? 'btn-light' : 'btn-light'}`} value={x.id} style={{ width: "100%", border: "1px solid pink" }}> <b>Bill No : {x.SaleMasDTO.shopvno}</b>, Type : {x.bltype} <br /> <span style={{ color: "red" }}><b>Bill Amt : <i class="fa-solid fa-indian-rupee-sign"></i> {x.SaleMasDTO.totblamt}</b></span></button></div>) })}</div>
      </div>
      <div className="row">
        <div className="col-11 text-end">
          {/* <button style={{ width: "20%" }} onClick={() => { setIsModalOpen_Payment(false) }} className='btn btn-dark'><i class="fa-solid fa-right-from-bracket"></i> Cancel</button> */}
        </div>
      </div>



      <Modal isOpen={isModalOpen_Payment} onRequestClose={handleCloseModal_Payment} contentLabel="Delete Confirmation" shouldCloseOnOverlayClick={false} className="modal-content" overlayClassName="modal-overlay">
        <div style={{ fontFamily: "Bahnschrift Condensed" }}>
          <h4 className='text-bg-dark rounded'><i class="fa-solid fa-indian-rupee-sign"></i> Payment</h4>
          <table style={{ width: "100%" }}>
            <tr><td className='text-end' style={{ width: "50%" }}><b>Type :       </b></td><td className='text-start'> &nbsp;<b >{paybltype}</b></td></tr>
            <tr><td className='text-end' style={{ width: "50%" }}><b>Bill No :    </b></td><td className='text-start'> &nbsp;<b>{payblno}</b></td></tr>
            <tr style={{ width: "50%", color: "red" }}><td className='text-end' style={{ width: "50%", color: "red" }}><b>Bill Amount :</b></td><td className='text-start'> &nbsp;<b>{payblAmt}</b></td></tr>
          </table>
          <div className='col-12 text-center p-1' style={{ display: "flex", fontFamily: "Bahnschrift Condensed", overflowX: "scroll", WebkitOverflowScrolling: "touch", scrollbarWidth: "none", msOverflowStyle: "none", }}> <style>{`.scroll-container::-webkit-scrollbar {display: none;}`}</style>
            {cmbAccdata.map((x, index) => (<div key={x.id} className="col-md-3 p-1 bor " style={{ border: "1px solid black", backgroundColor: selected === x.id ? 'lightblue' : 'white', flex: "0 0 auto" }}>
              <input type="radio" id={`id-${x.id}`} name="paymentnew" value={x.id} checked={selected === x.id} onChange={() => handleChange(x.id)} style={{ width: "10%" }} /> &nbsp;
              <label htmlFor={`id-${x.id}`} onClick={() => handleChange(x.id)} style={{ width: "80%" }} className='text-start'> {x.accname} </label><br />
            </div>))}
          </div>
          <button onClick={()=>{paybill();}} className='btn btn-danger'>Proceed to Pay</button> &nbsp;
          <button onClick={() => { setIsModalOpen_Payment(false) }} className='btn btn-dark'>Cancel</button>
        </div>
      </Modal>
      <ToastContainer autoClose={1000}></ToastContainer>
    </div>
  )
}

export default BillPaymentRest