import React, { useState } from 'react';
import './Shop_Create.css'; // Import CSS file for styling
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Loading from './Loading'
const Shop_Create = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = (e, flname) => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [savedata, setSavedata] = useState({
        id: "",
        cname: "",
        address: "",
        gstno: "",
        mobno: "",
        pincode: "",
        email: "",
        password: "",
        gst5: "0",
        gst12: "0",
        gst18: "0",
        gst28: "0",
        billprintline1: "-",
        billprintline2: "-",
        billprintline3: "-",
        billprintline4: "-",
        billprintline5: "-",
        restdlvchamt: "100",
        restdlvchperc: "10",
        restservicechperc: "12",
        billprintlineTC1: "-",
        billprintlineTC2: "-",
        billprintlineTC3: "-",
        billprintlineTC4: "-",
        billprintlineTC5: "-",
        gsttype: 0,
        fs_kottype: 0,
        fs_kotno: 0,
        itwashow: 0,
        print_hidekotno: 0,
        showtaxsummary: 0,
        
    });
    const { cname, address, gstno, mobno, pincode, email, password,fs_kottype,fs_kotno,itwashow,print_hidekotno,showtaxsummary } = savedata;

    const saveshop = async (e) => {

        setIsLoading(true);

        const inputValue = document.getElementById("cname").value.trim();
        if (inputValue === "") {
            document.getElementById("cname").focus();
            setIsLoading(false);
            toast.error("Please Enter Company Name")
            return false
        }


        const inputValue2 = document.getElementById("email").value.trim();
        if (inputValue2 === "") {
            document.getElementById("email").focus();
            setIsLoading(false);
            toast.error("Please Enter email address")
            return false
        }
        
        const inputValue3 = document.getElementById("password").value.trim();
        if (inputValue3 === "") {
            document.getElementById("password").focus();
            setIsLoading(false);
            toast.error("Please Enter password")
            return false
        }
        

        document.getElementById("cmdsave").disabled = true

        try {
            const nameQuery = `select * from shopmas where email = '${email}'`;
            const nameResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQuery)}`);
            if (nameResponse.data.length > 0) {
                toast.error("Email already exist..."); document.getElementById("cmdsave").disabled = false; setIsLoading(false); document.getElementById("email").focus();
                return false;
            }
    
            await axios.post(`${hostlink}/shopmas`, savedata)
                .then((res) => {
                    setIsModalOpen(false);
                    toast("Save Sucessfull")
                    setInterval(() => {
                        window.location.assign("/")
                    }, 1000);
                })
                .catch(() => {
                    setIsLoading(false);
                    document.getElementById("cmdsave").disabled = false
                    toast.error("Please Contact Software Provider..")
                })
                
        } catch (error) {
            
        }
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`$%^*+{}|;<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    };

    return (
        <div className="shop-create-container">
            <div className="background"></div>
            <div className='container p-3'>
                <div className=' text-start'>
                    <h1>New company registration</h1> <br />
                </div>
                <div className='row'>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Company Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='cname' value={cname} class="form-control" id="cname" /> <br />
                    </div>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> GST NO</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gstno' value={gstno} class="form-control" id="gstno" /> <br />
                    </div>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Address</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='address' value={address} class="form-control" id="address" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Pincode</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='pincode' value={pincode} class="form-control" id="pincode" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label"> Mobile No</label>
                        {/* <input type="text" onChange={(e) => { onChangevalue(e) }} name='mobno' value={mobno} class="form-control" id="mobno" /> <br /> */}
                        <input type="tel" placeholder="" onChange={(e) => { onChangevalue(e) }} pattern="[0-9]*" title="Please enter a 10-digit mobile number" required maxLength="10" onBlur={(e) => { const inputValue = e.target.value; if (inputValue.length < 10) { toast.error("Please enter valid mobile number");  } }} onKeyPress={(e) => { if (e.target.value.length >= 10 || isNaN(Number(e.key))) { e.preventDefault(); } }} name='mobno' value={mobno} class="form-control" id="mobno" />
                    </div>

                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Email</label>
                        <input type="email"  onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="email" /> <br />
                    </div>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Password</label>
                        <input type="password" onChange={(e) => { onChangevalue(e) }} name='password' value={password} class="form-control" id="password" /> <br />
                    </div>
                    <div class="col-md-6">
                    {/* <label className='font-monospace' class="form-label">Recovery email address</label>
                        <input type="recoveryemail"  onChange={(e) => { onChangevalue(e) }} name='recoveryemail' value={recoveryemail} class="form-control" id="recoveryemail" /> <br /> */}
                    </div>

                    <div class="col-md-6 text-end">
                        <br />
                        <button id='cmdsave' onClick={(e) => saveshop()} class="btn btn-outline-primary">Save</button>  &nbsp;
                        <a href='/' type="submit" class="btn btn-outline-dark">Cancel</a>
                    </div>

                </div>
            </div>
            <br />
            <marquee className='bg-black text-light p-1 mt-1' behavior="alternate" direction="right" >For more detail Contact on Mobile no : +91-9910496797, Email : info@crossrug.in </marquee>
            <marquee className='bg-danger text-light p-1 mt-1' behavior="alternate" direction="left">ERP S/w, Retail Billing s/w, POS System, Restaurant Billing s/w, Payroll S/w, Account & inventory s/w</marquee>
            <marquee className='bg-black text-light p-1 mt-1' behavior="alternate" direction="left">Crossrug Technologies offering customized software solutions for your business according to your requirement. We have multiple software products with us with all required modules. (for more detail Contact on Mobile no : +91-9910496797, Email : info@crossrug.in)Crossrug Technologies offering customized software solutions for your business according to your requirement. We have multiple software products with us with all required modules. (for more detail Contact on Mobile no : +91-9910496797, Email : info@crossrug.in) </marquee>

            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div>
                    <img src="./please_wait.gif" alt="please wait creatig company..." style={{ width: "100%" }} />
                </div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    );
};

export default Shop_Create;
