import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import Loading from './Loading'

const DishHeadMaster = () => {
    const [isLoading, setIsLoading] = useState(false);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const api = "/dhmas";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState()
    const [DishType, setDishType] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;


    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };


    const [logdetail, setlogdetail] = useState({
        onform: "DishHead",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid:usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const Closeform = () => {
        window.location.assign("/DishHeadMaster")
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(`${hostlink}/logbook`)
        console.log(ld)
        console.log("Log Saved...")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => {})
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => {})
        }
    }

    const SaveData = async (e) => {
        const inputValue =  document.getElementById("inputname").value.trim();
        if (inputValue === "") 
        {
            toast.error("Please Enter Name")
            return false
        }
        document.getElementById("cmdsave").disabled = true
        try {
            if (pagemode === '0') {
                console.log("ddd")
                var ld = { ...savedata, narration: usershopid+document.getElementById("inputname").value.trim()}
                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = { ...logdetail, newdesc: document.getElementById("inputname").value.trim(), operation: "New" }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/DishHeadMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                var ld = { ...savedata, narration: usershopid+document.getElementById("inputname").value.trim()}
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = { ...logdetail, newdesc: "Name : " + document.getElementById("inputname").value.trim() }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/DishHeadMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        dhname: "",
        dtcode: "",
        shopid: usershopid,
        narration:""
    })
    const { dhname,dtcode } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
        setlogdetail({ ...logdetail, newdesc: savedata});
        console.log(logdetail)
    }
    const cmdEdit = (e) => {
        setPagemode(1);
        setIsLoading(true);
        console.log(`${hostlink}${api}/${e}`)
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
                document.getElementById("pagemode").innerHTML = " Sub Category [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                document.getElementById("inputname").focus();
                document.getElementById("cmdnew").disabled = true
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
                setIsLoading(false);
                var ld = { ...logdetail, olddesc: "Name : " + Response.data.dhname, operation: "Edit" }
                setlogdetail(ld);

            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
        setPagemode(0);
        setlogdetail({ ...logdetail, operation: "New",olddesc: ""});
        document.getElementById("pagemode").innerHTML = " Sub Category [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
    }
    const deleteData = async (e) => {
        try {
            await axios.delete(`${hostlink}${api}/${e}`)
            var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete" }
            setlogdetail(ld);
            saveLog(ld)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                window.location.assign("/DishHeadMaster")
            }, 1000);
        } catch (error) { }
    }
    const columns = [
        { name: 'ID', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "15%" },
        { name: 'Head', sortable: true, selector: row => row[0].dhname, },
        { name: 'Type', sortable: true, selector: row => row[1].dtname, },
        {
            name: 'Actions', "width": "35%", cell: (row) => (<div>
                <Link to={"/DishHeadMaster"} onClick={(e) => { cmdEdit(`${row[0].id}`) }} className='btn btn-sm btn-outline-dark'> <i class="fa-solid fa-pen-to-square"></i> <span className="hide-on-mobile">Edit</span></Link> &nbsp;
                <Link onClick={() => handleOpenModal(row[0].id,row[0].dhname)} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile">Delete</span></Link>
                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this <br /> Dish Head : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>
            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}${api}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/${usershopid}/Dishtype`)
            .then((Response) => {
                setDishType(Response.data)
                setSavedata({ ...savedata, dtcode: Response.data[0].id});
            })
            .catch((Response) => { "data Not Found" })
    }, [])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-building-flag"></i> <span id='pagemode' > Sub Category [ New ]</span></h5>
                <div className='row'>
                    <div class="col-md-6">
                        <label className='font-monospace' class="form-label"> Sub Category Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='dhname' value={dhname} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-6">
                        <label for="inputState" class="form-label">Main Category Name</label>
                        <select id="inputState" onChange={(e) => { onChangevalue(e) }} name='dtcode' value={dtcode} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {DishType.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.dtname} </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div class="col-md-12 text-end">
                <br />
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-building-flag"></i> Sub Category Master</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width:"100%"}} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-building-flag"></i> &nbsp; Create New</button>
                    </div>
                    <div style={{ overflow: "auto" }} className='col-12 '>
                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default DishHeadMaster
