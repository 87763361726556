import React from 'react';

const PrintComponent = () => {
  const handlePrint = () => {
    // Message format: "1,http://13.51.137.38:8080/RestbillviewOne/1/1"
    const data = '1,http://13.51.137.38:8080/RestbillviewOne/1/2';
    
    // Check if ReactNativeWebView is available
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(data);
    } else {
      console.log('ReactNativeWebView not available.');
    }
  };

  return (
    <div>
      <h1>Content to Print</h1>
      <p>This content will be sent to the printer.</p>
      <button onClick={handlePrint}>Print</button>
    </div>
  );
};

export default PrintComponent;
