import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const Rpt_PurchaseReport = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [data, setData] = useState([]);
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 0
    });

    const { fromdate, todate, status } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async () => {
        // axios.get(`${hostlink}/getchehinroomservice/${usershopid}/${fromdate}/${todate}`)
        await axios.get(`${hostlink}/getchehinroomservice/${usershopid}/${fromdate}/${todate}`)
            .then((response) => {
                setData(response.data);
                console.log(response.data)
            })
            .catch((resp) => {
                console.log("Data Not Found...");
            });
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Bill Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode'> Purchase Report</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        {/* <label htmlFor="roomtype" className="form-label">Status:</label> */}
                    </div>
                    <div className='col-md-2'>
                        {/* <select id="roomtype" onChange={(e) => { onChangevalue(e) }} name='status' value={status} className="form-select">
                            <option value={3}>All</option>
                            <option value={1}>Trash</option>
                            <option value={2}>Cancelled</option>
                            <option value={0}>Billed</option>

                        </select> */}
                    </div>
                    <div className='col-md-12 align-self-center text-end mt-2'>
                        <button onClick={() => { loaddata(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        {/* <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Bill Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink> */}
                    </div>
                </div>
            </div>


            <br />
            <div className='container-fluid' style={{ maxHeight: "400px", overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th scope="col">Sr No</th>
                            <th scope="col">Bl Date</th>
                            <th scope="col">Bl No</th>
                            <th scope="col">Time</th>
                            {/* <th scope="col">Type</th> */}
                            {/* <th scope="col">Table</th> */}
                            {/* <th scope="col">Item Name</th> */}
                            {/* <th scope="col">Qty</th> */}
                            {/* <th scope="col">Item</th> */}
                            <th scope="col">Bill Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <tr key={x} >
                                <td>{x + 1}</td>
                                {/* <td>{res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td> */}
                                <td> {res[12] ? new Date(res[12]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</td>
                                <td>{res[11]}</td>

                                <td>{res[6] ? new Date(`2022-01-01T${res[6]}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</td>
                                <td>{res[9]}</td>
                                {/* <td>{res.SaleMasDTO.custname}</td> */}
                                {/* <td>{res.SaleMasDTO.itemview}</td> */}
                                {/* <td>{res.SaleMasDTO.totblamt}</td> */}
                            </tr>
                        ))}
                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td></td> */}
                            {/* <td></td> */}
                            {/* <td></td> */}
                            {/* <td></td> */}
                            {/* <td></td> */}
                            <td><h6>Total :</h6></td>
                            <td><h6>{data.reduce((total, res) => total + parseFloat(res[9]), 0)}</h6></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
// const PDFDocument = ({ data, fromdate, todate }) => {
//     // Calculate total bill amount
//     const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
//     return (
//         <Document>
//             <Page orientation="landscape">
//                 <View style={styles.container}>
//                     <Text style={styles.title}>Bill Report</Text>
//                     <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
//                     <View style={styles.table}>
//                         <View style={styles.tableRow}>
//                             <Text style={styles.headerCell}>Sr No</Text>
//                             <Text style={styles.headerCell}>Bl Date</Text>
//                             <Text style={styles.headerCell}>Bl No</Text>
//                             <Text style={styles.headerCell}>Time</Text>
//                             <Text style={styles.headerCell}>Type</Text>
//                             <Text style={styles.headerCell}>Table</Text>
//                             <Text style={styles.headerCell}>Mobile No</Text>
//                             <Text style={styles.headerCell}>Cust Name</Text>
//                             <Text style={styles.headerCell}>Item</Text>
//                             <Text style={styles.headerCell}>Bill Amt</Text>
//                         </View>
//                         {data.map((res, x) => (
//                             <View key={x} style={styles.tableRow}>
//                                 <Text style={styles.cell}>{x + 1}</Text>
//                                 <Text style={styles.cell}>{res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
//                                 <Text style={styles.cell}>{res.SaleMasDTO.shopvno}</Text>
//                                 <Text style={styles.cell}>{res.SaleMasDTO.entrytime ? new Date(`2022-01-01T${res.SaleMasDTO.entrytime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</Text>
//                                 <Text style={styles.cell}>{res.bltype}</Text>
//                                 <Text style={styles.cell}>{res.tablename}</Text>
//                                 <Text style={styles.cell}>{res.SaleMasDTO.custmob}</Text>
//                                 <Text style={styles.cell}>{res.SaleMasDTO.custname}</Text>
//                                 <Text style={styles.cell}>{res.SaleMasDTO.itemview}</Text>
//                                 <Text style={styles.cell}>{res.SaleMasDTO.totblamt}</Text>
//                             </View>
//                         ))}
//                         <View style={styles.tableRow}>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}>Total :</Text>
//                             <Text style={styles.cell}>{totalBillAmount}</Text>
//                         </View>
//                     </View>
//                 </View>
//             </Page>
//         </Document>
//     );
// };

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});



export default Rpt_PurchaseReport