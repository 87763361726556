import axios from 'axios';
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Settings = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [data, setData] = useState({
        id: "",
        gst5: "",
        gst12: "",
        gst18: "",
        gst28: "",
        billprintline1: "",
        billprintline2: "",
        billprintline3: "",
        billprintline4: "",
        billprintline5: "",
        restdlvchamt: "",
        restdlvchperc: "",
        restservicechperc: "",
        billprintlineTC1: "",
        billprintlineTC2: "",
        billprintlineTC3: "",
        billprintlineTC4: "",
        billprintlineTC5: "",
        gsttype: 0,
        showtaxsummary: 0,
        changecheckoutdate: 0,
        print_hidekotno:0,
        fs_kottype:0,
        fs_kotno:0,
        itwashow:0,
        dcotont:0
    })
    const { gst5, gst12, gst18, gst28, billprintline1, billprintline2, billprintline3, billprintline4, billprintline5, billprintlineTC1, billprintlineTC2, billprintlineTC3, billprintlineTC4, gsttype, showtaxsummary, billprintlineTC5, restdlvchamt, restdlvchperc, restservicechperc, changecheckoutdate,print_hidekotno,fs_kottype,fs_kotno,itwashow,dcotont } = data;

    const calcelForm = (e) => {
        window.location.assign("/")
    }

    const savedata = async () => {
        console.log(data)
        await axios.put(`${hostlink}/shopmas`, data)
            .then((responce) => {
                toast.success("Save Sucessfull")
                setInterval(() => {
                    localStorage.removeItem('MYPCKARTUSER');
                    window.location.assign("/")
                }, 1000);
            })
            .catch(() => {
                toast.error("Something went wrong, Please check it..")
            })
    }

    const onchange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        axios.get(`${hostlink}/shopmas/${usershopid}`)

            .then((resp) => {
                setData(resp.data)
            })
            .catch(() => {
                console.log("Data Not Fatch...")
            })
    }, [1])

    return (
        <div>
            <div className='container p-1'>
                <h5 className='font-monospace text-danger'>System Configuration</h5>
                <div className='row'>
                    {/* Column 1 */}
                    <div className='col-md-4'>
                        {/* <div className='row'>
                            <div className='col-md-6 '><label htmlFor="">Room GST 5 % on Amount </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='gst5' value={gst5} type="text" /></div>
                            <div className='col-md-6 '><label html For=""> Room GST 12 % on Amount </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} name='gst12' value={gst12} type="text" /></div>
                            <div className='col-md-6 '><label htmlFor=""> Room GST 18 % on Amount </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} name='gst18' value={gst18} type="text" /></div>
                            <div className='col-md-6 '><label htmlFor=""> Room GST 28 % on Amount </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="text" name='gst28' value={gst28} /></div>
                            <p></p>
                            <hr />
                            <div className='col-md-6 '><label htmlFor=""> Service charge % </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="number" name='restservicechperc' value={restservicechperc} maxLength={2} /></div>
                            <div className='col-md-6 '><label htmlFor=""> Delivery charge % </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="number" name='restdlvchperc' value={restdlvchperc} maxLength={2} /></div>
                            <div className='col-md-6 '><label htmlFor=""> Delivery charge Amount </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="number" name='restdlvchamt' value={restdlvchamt} /></div>

                        </div> */}
                    </div>
                    {/* Column 2 */}
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-1 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline1' value={billprintline1} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-2 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline2' value={billprintline2} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-3 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline3' value={billprintline3} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-4 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline4' value={billprintline4} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-5 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline5' value={billprintline5} type="text" /></div>

                            <div className='col-md-3 '><label htmlFor=""> T&C 1 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC1' value={billprintlineTC1} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> T&C 2 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC2' value={billprintlineTC2} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> T&C 3 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC3' value={billprintlineTC3} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> T&C 4 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC4' value={billprintlineTC4} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> T&C 5 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC5' value={billprintlineTC5} type="text" /></div>

                        </div>
                    </div>

                    {/* Column 3 */}
                    <div className='col-md-4'>
                        <div className='row'>
                            {/* <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Recovery email address : </label></div>
                            <div className='col-md-5 '><input type="recoveryemail"  onChange={(e) => { onchange(e) }} name='recoveryemail' value={recoveryemail} class="form-control" id="recoveryemail" /></div> */}

                            {/* <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Change Checkout Date : </label></div>
                            <div className='col-md-5 '><select id="inputState" onChange={(e) => { onchange(e) }} name='changecheckoutdate' value={changecheckoutdate} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div> */}

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Show Tax Summary : </label></div>
                            <div className='col-md-5 '><select id="inputState" onChange={(e) => { onchange(e) }} name='showtaxsummary' value={showtaxsummary} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>
                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Tax Type : </label></div>
                            <div className='col-md-5 '><select id="inputState" onChange={(e) => { onchange(e) }} name='gsttype' value={gsttype} class="form-select"><option selected key={0} value={0}>Exclude</option><option selected key={1} value={1}>Include</option></select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Item wise Amount : </label></div>
                            <div className='col-md-5 '><select id="itwashow" onChange={(e) => { onchange(e) }} name='itwashow' value={itwashow} class="form-select"><option selected key={0} value={0}>Non Taxable</option><option selected key={1} value={1}>Taxable</option></select> </div>
                            {/* <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Hide Kot Srno : </label></div>
                            <div className='col-md-5 '><select id="inputState" onChange={(e) => { onchange(e) }} name='print_hidekotno' value={print_hidekotno} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div> */}

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Devlivery Charge On : </label></div>
                            <div className='col-md-5 '><select id="dcotont" onChange={(e) => { onchange(e) }} name='dcotont' value={dcotont} class="form-select"><option selected key={0} value={0}>Taxable</option><option selected key={1} value={1}>Non Taxable</option></select> </div>


                            {/* <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Kot No Font size : </label></div>
                            <div className='col-md-5 '><input onChange={(e) => { onchange(e) }} name='fs_kotno' value={fs_kotno} type="text" /></div> */}

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Kot Type Font size : </label></div>
                            <div className='col-md-5 '><input onChange={(e) => { onchange(e) }} name='fs_kottype' value={fs_kottype} type="text" /></div>

                        </div>
                    </div>

                    {/* Save Button */}
                    <div className='col-md-6'></div>
                    <div className='col-md-6 text-end'>
                        <br />
                        <button class="btn btn-primary" onClick={() => { savedata() }}>Save</button>  &nbsp;
                        <button class="btn btn-outline-dark" onClick={() => { calcelForm() }}>Cancel</button>
                    </div>

                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default Settings