const Home = () => {
    return (
        <div style={{ background: "linear-gradient(rgb(0,0,0,0.5),rgb(0,0,0,0.5)) , url('/b1.jpeg')", height: "92vh", backgroundRepeat: "no-repeat", width: "100%", backgroundAttachment: "fixed", backgroundSize: "100% 100%" }}>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 shadow-sm text-end" style={{ height: "30vh" }}><br /><img className="shadow-lg" src="./c2.png" alt="" style={{ height: "100%", borderRadius: "50%" }} /> </div>
                </div> <br /> <br /><br /><br /><br /><br />

                <div className="row">
                    <div className="col-md-6 shadow-sm" style={{ height: "20vh" }}><br /><img className="shadow-lg" src="./b3.png" alt="" style={{ height: "100%", borderRadius: "100px" }} /> </div>
                    <div className="col-md-6 shadow-sm text-end" style={{ height: "20vh" }}><br /><img className="shadow-lg" src="./b3.png" alt="" style={{ height: "100%", borderRadius: "100px" }} /> </div>
                </div>
            </div>            <br /><br />

            <div className='container-fluid '>
                <div className='row'>
                    <div className='col-md-5 p-2 bg-light' style={{ borderEndEndRadius: "20px", borderStartEndRadius: "20px" }}>
                        <h6>Booked Room</h6>
                        <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar bg-danger w-50" >50%</div>
                        </div>
                    </div>
                    <div className='col-md-2 p-2' />
                    <div className='col-md-5 p-2 bg-light' style={{ borderStartStartRadius: "20px", borderEndStartRadius: "20px" }}>
                        <h6>Available Room </h6>
                        <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar bg-success w-50" >50%</div>
                        </div>
                    </div>
                </div>
            </div>
            <marquee className='bg-black text-light p-1 mt-1' behavior="alternate" direction="left">Crossrug Technologies offering customized software solutions for your business according to your requirement. We have multiple software products with us with all required modules. (for more detail Contact on Mobile no : +91-9910496797, Email : info@crossrug.in) </marquee>

        </div>
    )
}

export default Home
