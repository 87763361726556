import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
const SaleBill = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const [AccCode, setAccCode] = useState([])
    const { deleteid, deletemsg } = messagedata;
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [blno, setblno] = useState(0)
    const [isModalOpenCancel, setIsModalOpenCancel] = useState(false);
    const handleOpenModal = (e, blnoprint) => { setblno(e); setMessagedata({ ...messagedata, deleteid: e, deletemsg: blnoprint }); setIsModalOpen(true); };
    const handleOpenModaledit = (e) => { setblno(e); setIsModalOpenEdit(true); };
    const handleOpenModalCancel = (e, blnoprint) => { setblno(e); setMessagedata({ ...messagedata, deleteid: e, deletemsg: blnoprint }); setIsModalOpenCancel(true); };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleCloseModalCancel = () => { setIsModalOpenCancel(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const handleNoClickCancel = () => { console.log('User clicked No'); handleCloseModalCancel(); };
    const handleCloseModalEdit = () => { setIsModalOpenEdit(false); window.location.assign("/SaleBill") };
    const handleNoClickEdit = () => { console.log('User clicked No'); handleCloseModalEdit(); };
    var billgst;

    var billgst;
    const [logroom, setLogroom] = useState();
    const [GST_P_1, setGST_P_1] = useState(0);
    const [GST_P_2, setGST_P_2] = useState(0);
    const [GST_P_3, setGST_P_3] = useState(0);
    const [GST_P_4, setGST_P_4] = useState(0);

    let bl_Tot_1 = 0;
    let bl_RoomRent = 0;
    let bl_Disc = 0;
    let bl_total = 0;
    let bl_gstTotal = 0;
    let bl_blamt = 0;

    const [roomdetail, setroomdetail] = useState([]);
    const today = new Date();
    const [refreshData, setRefreshData] = useState(false)
    const api = "/bill";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const [roomrentcalculation, setroomrentcalculation] = useState([]);
    const [newItemdata, setItemdata] = useState({ chdate: '', rtype: 0, roomtypeview: '', roomnoview: '', rcode: 0, rent: 0, exbed: 0, exbedch: 0, chid: 0 });
    const Closeform = () => {
        window.location.assign("/SaleBill")
    }
    const [viewbldate, setBldate] = useState({
        blfromdate: today.toISOString().split('T')[0],
        bltodate: today.toISOString().split('T')[0]
    })
    const { blfromdate, bltodate } = viewbldate;
    const bldatechange = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setBldate({ ...viewbldate, [name]: value })
        setRefreshData(true)
    }
    const [logdetail, setlogdetail] = useState({
        onform: "SALE",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const handleCheckout = async (e, id, rnamedata) => {

        await updateStatus(id); // Pass both the event object and the ID to updateStatus
        handleOpenModaledit(rnamedata)
        // billtotal(); // Call billtotal after updating status


    };
    const getrent = (e) => {
        axios.get(`${hostlink}/room/${e}`)
            .then((Response) => {

                document.getElementById("extrabadchrge").value = Response.data.exrent
                document.getElementById("roomrent").value = Response.data.rent
                var ld = { ...savedata, rent: Response.data.rent, exbedch: Response.data.exrent }
                setSavedata(ld);
            })
            .catch((Response) => { "data Not Found" })
    }
    const getroombyid = (e) => {

        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/checkoutview`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }

    const saveBill = async (e) => {
        const rnameString = roomdetail.map(item => item.roomnoview).join(',');
        const rcodeString = roomdetail.map(item => item.rcode).join(',');

        var ld = { ...billdata, roomdescription: rnameString, roomdescriptioncode: rcodeString, narration: usershopid + document.getElementById("checkoutdate").value.trim() + document.getElementById("inputname").value.trim() }
        await axios.put(`${hostlink}/bill`, ld)
            .then((respbill) => {
                var billno;
                billno = (respbill.data)
                var ld = {
                    ...logdetail,
                    newdesc: `Bill No : ${respbill.data}, Mob No : ${guestmob}, Name : ${guestname}`,
                    operation: "Bill", olddesc: "",
                }; setlogdetail(ld); saveLog(ld)
                // axios.post(`${hostlink}/checkin/blno/${respbill.data}/${checkinid}`)
                // axios.post(`${hostlink}/checkin/bldate/${checkoutdate}/${checkinid}`)
                // axios.post(`${hostlink}/checkin/statusbill/${checkinid}`)
                // const rcodeString = roomdetail.map(item => item.rcode).join(',');
                // axios.post(`${hostlink}/room/updateroombulk/0/${rcodeString}`)
                toast("Save Sucessfull")
                setTimeout(() => {
                    window.location.assign("/SaleBill")
                }, 1000);
            })
            .catch(() => {
                console.log("Data Not Save")
            })
    }

    const SaveData = async (e) => {
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter Name")
            return false
        }
        try {
            if (document.getElementById("pagemode").innerHTML == ' Check-OUT [ NEW ]') {
                var ld = { ...savedata, narration: usershopid + document.getElementById("checkoutdate").value.trim() + document.getElementById("inputname").value.trim() }
                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = {
                            ...logdetail,
                            newdesc: `Mob No : ${mobno}, Name : ${gname}, Room : ${logroom}, Rent : ${rent}, Ex Bed : ${exbed}, Bed Ch : ${exbedch} , Advance : ${advance}`,
                            operation: "New"
                        }
                        setlogdetail(ld);
                        saveLog(ld)
                        // Update Room Status
                        axios.post(`${hostlink}/room/status/${document.getElementById("roomcode").value}`)

                            .then(() => { })
                            .catch(() => { })
                        setTimeout(() => {
                            setRefreshData(true)
                        }, 1000);
                    })
                    .catch(() => {

                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                var ld = { ...savedata, narration: usershopid + document.getElementById("checkoutdate").value.trim() + document.getElementById("inputname").value.trim() }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        axios.get(`${hostlink}/room/${rcode}`)
                            .then((responce) => {
                                var ld = {
                                    ...logdetail,
                                    newdesc: `Mob No : ${mobno}, Name : ${gname}, Room : ${responce.data.rname}, Rent : ${rent}, Ex Bed : ${exbed}, Bed Ch : ${exbedch} , Advance : ${advance}`,
                                    operation: "Edit"
                                }
                                setlogdetail(ld);
                                saveLog(ld)
                            })
                        toast("Save Sucessfull")
                        setTimeout(() => {


                            window.location.assign("/SaleBill")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        indate: "",
        mobno: "",
        gname: "",
        add1: "",
        add2: "",
        documentno: "",
        documentpath: "",
        email: "",
        gstno: "",
        cname: "",
        rtype: "",
        rcode: "",
        rent: "",
        exbed: "",
        exbedch: "",
        mg: "",
        fg: "",
        cg: "",
        stday: "",
        discount: "",
        advance: "",
        paidby: "",
        remarks: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        acccode: 0
    })

    const { mobno, gname, add1, add2, documentno, documentpath, indate, email, gstno, cname, rcode, rtype, rent, exbed, exbedch, mg, fg, cg, stday, discount, advance, paidby, remarks } = savedata;
    const [billdata, setBilldata] = useState({
        id: "",
        checkindate: "",
        checkoutdate: today.toISOString().split('T')[0],
        bldate: today.toISOString().split('T')[0],
        roomcode: "",
        guestroomname: "",
        roomrent: 0,
        extrabed: 0,
        extrabedch: 0,
        discountperc: 0,
        adddiscountamt: 0,
        additionalcharge: 0,
        advanceamount: 0,
        blpaidby: "",
        guestmob: "",
        guestname: "",
        guestadd1: "",
        guestadd2: "",
        guestdocno: "",
        guestemail: "",
        guestgstno: "",
        guestcompanyname: "",
        blremarks: "",
        bltotaldays: "",
        bltotal: "",
        blroomrent: "",
        discountpercamt: "",
        blgstperc: "",
        guestroomname: "",
        blgstamt: "",
        blamt: "",
        netpayamt: "",
        checkinid: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        roomdescription: "",
        roomdescriptioncode: "",
        acccode: 0,
        roomserviceamt:0
    })
    const { checkinid, checkindate, acccode, checkoutdate, roomcode, roomrent,  extrabed, extrabedch, discountperc, roomserviceamt,adddiscountamt, additionalcharge, advanceamount, blpaidby, guestmob, guestname, guestadd1, guestadd2, guestdocno, guestemail, guestgstno, guestcompanyname, blremarks, bltotaldays, bltotal, blroomrent, discountpercamt, blgstperc, blgstamt, blamt, netpayamt } = billdata;


    const billtotal = (e) => {
        var value = bl_Tot_1
        var ld = {
            ...billdata, bltotaldays: bl_Tot_1,
            bltotal: (value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100),
            bldate: document.getElementById("checkoutdate").value,
            blroomrent: bl_RoomRent,
            discountpercamt: bl_Disc,
            blgstamt: bl_gstTotal,
            bltotal: (bl_RoomRent - bl_Disc),
            blamt: ((((bl_RoomRent - bl_Disc) + (bl_gstTotal)+roomserviceamt) + Number(additionalcharge)) - Number(adddiscountamt)),
            netpayamt: (((((bl_RoomRent - bl_Disc) + (bl_gstTotal) +roomserviceamt) + Number(additionalcharge)) - Number(adddiscountamt)) - Number(advanceamount))
        }
        setBilldata(ld);
        setIsModalOpenEdit(false);
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const onChangevaluebill = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setBilldata({ ...billdata, [name]: sanitizedValue });
    }

    const updateStatus = async (e) => {
        let x = document.getElementById("frm_checkoutdetail")
        x.style.display = "block"
        await axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setBilldata(Response.data)
                console.log(Response.data)
                // var checkindateString = Response.data.indate
                // var checkoutdateString = document.getElementById("checkoutdate").value;
                // var checkindate = new Date(checkindateString);
                // var checkoutdate = new Date(checkoutdateString);
                // if (!isNaN(checkindate) && !isNaN(checkoutdate)) {
                //     var differenceInMilliseconds = Math.abs(checkoutdate - checkindate);
                //     var differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
                //     var value = differenceInDays;


                // } else {
                //     console.log("Invalid date format");
                // }
                // var ld = {
                //     ...billdata, checkindate: Response.data.indate, roomcode: Response.data.rcode, roomrent: Response.data.rent, extrabed: Response.data.exbed, extrabedch: Response.data.exbedch, discountperc: Response.data.discount, advanceamount: Response.data.advance, blpaidby: Response.data.paidby, guestmob: Response.data.mobno, guestname: Response.data.gname, guestadd1: Response.data.add1, guestadd2: Response.data.add2, guestdocno: Response.data.documentno, guestemail: Response.data.email, guestgstno: Response.data.gstno, guestcompanyname: Response.data.cname, blremarks: Response.data.remarks, bltotaldays: value,
                //     bltotal: (value * (Response.data.rent + (Response.data.exbed * Response.data.exbedch))) - Number((value * (Response.data.rent + (Response.data.exbed * Response.data.exbedch))) * Response.data.discount / 100),
                //     blroomrent: (Response.data.rent + (Response.data.exbed * Response.data.exbedch)),
                //     discountpercamt: (value * (Response.data.rent + (Response.data.exbed * Response.data.exbedch))) * Response.data.discount / 100,
                //     blgstperc: billgst, gueststaydays: value, checkinid: e,
                // }
                // setBilldata(ld);
                var ld = {
                    ...logdetail,
                    olddesc: `Mob No : ${Response.data.mobno}, Name : ${Response.data.gname}, Rent : ${Response.data.rent}, Ex Bed : ${Response.data.exbed}, Bed Ch : ${Response.data.exbedch} , Advance : ${Response.data.advance}`,
                    operation: "Check-Out"
                }
                setlogdetail(ld);

                axios.get(`${hostlink}/getchehinroomDetail/${Response.data.checkinid}`)
                    .then((resp) => {
                        setroomdetail(resp.data)

                    })
            })

            .catch((res) => { console.log("Data Not find") })
    }


    const deleteData = async (e) => {
        try {
            await axios.post(`${hostlink}/bill/1/${e}`)
                .then((responce) => {
                    axios.get(`${hostlink}${api}/${e}`)
                        .then((responce) => {
                            var ld = {
                                ...logdetail,
                                newdesc: `Bill No : ${responce.data.id}, Bl Date : ${responce.data.bldate}, Bill Amt : ${responce.data.blamt}`,
                                operation: "Delete-Bill"
                            }
                            setlogdetail(ld); saveLog(ld);
                        })
                })
            toast.success("Delete Sucessfull")
            setInterval(() => {
                handleCloseModal();
                window.location.assign("/SaleBill")
                setRefreshData(true)
            }, 1000);
        } catch (error) { }
    }
    const cancelBill = (e) => {

        axios.post(`${hostlink}/bill/2/${e}`)
            .then((Response) => {

                axios.get(`${hostlink}${api}/${e}`)
                    .then((responce) => {
                        var ld = {
                            ...logdetail,
                            newdesc: `Bill No : ${responce.data.id}, Bl Date : ${responce.data.bldate}, Bill Amt : ${responce.data.blamt}`,
                            operation: "Cancel-Bill"
                        }
                        setlogdetail(ld); saveLog(ld);
                    })
                handleCloseModalCancel();
                toast.success("Cancel Sucessfull")
                setRefreshData(true)
                console.log(Response.data)
            })
            .catch(() => {

            })
        //  setRefreshData(true)
    }
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        document.getElementById("cmdsave").disabled = false
        setRefreshData(false)
        axios.get(`${hostlink}/${usershopid}/billview/0/${blfromdate}/${bltodate}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/rtmas`)
            .then((Response) => {
                setRoomTYpe(Response.data)
            })
            .catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/${usershopid}/accmas`)
            .then((Response) => {
                setAccCode(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }, [refreshData])
    return (
        <div className='p-1 ' >
            <div id='frm_checkoutdetail' className='container shadow rounded mt-1 ' style={{ display: "none" }}>
                <h5 className='font-monospace text-primary mt-1'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemodeout' > Bill Edit</span></h5>
                <div className='col-md-12'>
                    <div className='row'>
                        <div class="col-md-9">
                            <div className='container'>
                                <div className='row'>
                                    {/* Guest Detail */}

                                    <div className='container p-1'>
                                        <div className='row bg-info shadow rounded mt-1 align-self-end p-1' style={{ backgroundImage: 'linear-gradient(to right, #6AF4D7, #ff9999, #6AF4D7)', color: 'white', padding: '20px' }}>
                                            <div className='col-md-8 align-self-end ' >
                                                <h5 className='font-monospace text-primary text-black'> <i class="fa-regular fa-address-card"></i> <span id='pagemodeaaa' > Guest Details On Bill</span></h5>
                                            </div>
                                            <div className='col-md-2 align-self-end text-end'>
                                                <label htmlFor="todate" className="form-label font-monospace text-black">Bill Date:</label>
                                            </div>
                                            <div className='col-md-2'>
                                                <input type="date" name='checkoutdate' value={checkoutdate} id="checkoutdate" className="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <label className='font-monospace' class="form-label">Mob No</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestmob' value={guestmob} class="form-control" /> <br />
                                    </div>
                                    <div class="col-md-4">
                                        <label className='font-monospace' class="form-label">Guest Name</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestname' value={guestname} class="form-control" /> <br />
                                    </div>
                                    <div class="col-md-3">
                                        <label className='font-monospace' class="form-label">Add 1</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestadd1' value={guestadd1} class="form-control" /> <br />
                                    </div>
                                    <div class="col-md-3">
                                        <label className='font-monospace' class="form-label">Add 2</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestadd2' value={guestadd2} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Document No</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestdocno' value={guestdocno} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-4">
                                        <label className='font-monospace' class="form-label">Select Document</label>
                                        <input type="file" class="form-control" id="documentpath" />
                                    </div>
                                    <div className="col-md-3">
                                        <label className='font-monospace' class="form-label">Email</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestemail' value={guestemail} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-3">
                                        <label className='font-monospace' class="form-label">GST No</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestgstno' value={guestgstno} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-3">
                                        <label className='font-monospace' class="form-label">Company Name</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestcompanyname' value={guestcompanyname} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-4">
                                        <label className='font-monospace' class="form-label">Remarks</label>
                                        <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='blremarks' value={blremarks} class="form-control" /> <br />
                                    </div>
                                    <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Details</span></h5>

                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Discount %</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e); }} name='discountperc' value={discountperc} onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Flat Discount</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='adddiscountamt' value={adddiscountamt} onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='font-monospace' class="form-label">Add. Charge</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='additionalcharge' value={additionalcharge} onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div className="col-md-3">
                                        <label className='font-monospace' class="form-label">Advance</label>
                                        <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='advanceamount' value={advanceamount} onBlur={(e) => { billtotal() }} class="form-control" /> <br />
                                    </div>
                                    <div class="col-md-3">
                        <label for="inputState" class="form-label">Paid By</label>
                        <select id="acccode" onChange={(e) => { onChangevaluebill(e) }} name='acccode' value={acccode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {AccCode.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.accname} </option>
                                )
                            })}
                        </select>
                    </div>
                                </div>
                            </div>



                        </div>
                        {/* Summary  Detail */}
                        <div class="col-md-3 " >
                            <div class="col-md-12 shadow rounded " style={{ borderRadius: "50px 50px 50px " }}>
                                <br />
                                <h5 className='font-monospace text-primary text-center'>  <i class="fa-solid fa-right-from-bracket"></i>  <span id='pagemodesummary' > Summary</span></h5>
                                <div className='container border-1'>
                                    <div className='row'>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Total Days :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' name='bltotaldays' value={bltotaldays} class="form-label">{bltotaldays}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Room Rent :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' name='blroomrent' value={blroomrent} class="form-label">{parseFloat(blroomrent).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                        </div>
                                        <div className='col-8   text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Discount :</label>
                                        </div>
                                        <div className='col-4  text-end'>
                                            <label className='font-monospace ' name='discountpercamt' value={discountpercamt} class="form-label">{parseFloat(discountpercamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                        </div>

                                        <div className='col-8  bg-info-subtle text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Total :</label>
                                        </div>
                                        <div className='col-4  bg-info-subtle text-end'>
                                            <label className='font-monospace ' name='bltotal' value={bltotal} class="form-label">{parseFloat(bltotal).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">GST Amount : </label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' class="form-label">{parseFloat(blgstamt).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Additional Charge :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' class="form-label">{additionalcharge}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Additional Discount :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' class="form-label">{adddiscountamt}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Room Service :</label>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <label className='font-monospace ' class="form-label">{roomserviceamt}</label>
                                        </div>
                                        <div className='col-8 bg-warning-subtle text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Bill Amount :</label>
                                        </div>
                                        <div className='col-4 bg-warning-subtle text-end'>
                                            <label className='font-monospace' class="form-label">{parseFloat(blamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label>
                                        </div>
                                        <div className='col-8  text-end'>
                                            <label className='font-monospace text-end ' class="form-label">Advance :</label>
                                        </div>
                                        <div className='col-4  text-end'>
                                            <label className='font-monospace' class="form-label">{parseFloat(advanceamount).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label>
                                        </div>
                                        <div className='col-8 bg-warning-subtle text-end'>
                                            <strong> <label className='font-monospace text-end' class="form-label">Net Payable Amount :</label></strong>
                                        </div>
                                        <div className='col-4 bg-warning-subtle text-end'>
                                            <strong> <label className='font-monospace bg-danger ' class="form-label">{parseFloat(netpayamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label></strong>
                                        </div>

                                        <div class="col-md-12  text-end">
                                            <br />
                                            <button id='cmdsave' onClick={() => { saveBill() }} class="btn btn-primary">Save</button>  &nbsp;
                                            <button onClick={Closeform} type="submit" class="btn btn-outline-dark">Cancel</button>
                                            <br />
                                            <br />
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Stay Details</span></h5>
                        <div className='show-on-mobile row bg-info p-1 m-0 container shadow rounded align-content-end border border-1' style={{ backgroundImage: 'linear-gradient(to right, #EC90DE, #ff9999, #6AF4D7)', color: 'black', padding: '20px' }}>


                            {roomdetail.map((resp, index) => {
                                const dateDifference = Math.floor((new Date(checkoutdate) - new Date(resp.chdate)) / (1000 * 60 * 60 * 24));
                                const displayStday = dateDifference === 0 ? 1 : dateDifference;
                                var currRent = (((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100))
                                if (Number(GST_P_1) > Number(currRent)) {
                                    billgst = "0";
                                } else if (Number(GST_P_2) > Number(currRent)) {
                                    billgst = "5";
                                } else if (Number(GST_P_3) > Number(currRent)) {
                                    billgst = "12";
                                } else if (Number(GST_P_4) > Number(currRent)) {
                                    billgst = "18";
                                } else if (Number(GST_P_4) <= Number(currRent)) {
                                    billgst = "28";
                                }

                                // bl_Tot_1 = bl_Tot_1 + Number(displayStday)
                                // bl_RoomRent = bl_RoomRent + Number(((resp.exbed * resp.exbedch) + resp.rent) * displayStday)
                                // bl_Disc = bl_Disc + Number((((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100) * displayStday)
                                // bl_total = bl_total + Number((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * displayStday)
                                // bl_gstTotal = bl_gstTotal + Number(((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * billgst / 100) * displayStday)
                                return (
                                    <div key={index} className='row text-center align-self-center p-1 m-0 bg-light border border-1'>
                                        <h6 style={{ "color": "blue" }}>Room : {resp.roomnoview}</h6>
                                        <div className='col-12 text-start' style={{ fontSize: "15px", color: "black" }}>
                                            <table style={{ "width": "100%" }}>
                                                <tr><td>Check In &nbsp;</td><td>: {resp.chdate ? new Date(resp.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td></tr>
                                                <tr><td>Rent</td><td>: {parseFloat(resp.rent).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</td></tr>
                                                <tr><td>Extra bed</td><td>: {resp.exbed}</td></tr>
                                                <tr><td>Bed Charge</td><td>: {parseFloat(resp.exbedch).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</td></tr>
                                                <tr><td>Dis %</td><td>: {discountperc}</td></tr>
                                                <tr><td>@ Day</td><td>: {parseFloat(((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</td></tr>
                                                <tr><td>St.Days</td><td>: {displayStday}</td></tr>
                                                <tr><td>GST %</td><td>: {billgst}</td></tr>
                                                <tr><td>GST Amt</td><td>: {parseFloat(((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * billgst / 100) * displayStday).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</td></tr>
                                                <tr ><td><b>Total</b></td><td><b> : {parseFloat(((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * displayStday) + (((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * billgst / 100) * displayStday)).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</b></td></tr>
                                            </table>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                        <div className='hide-on-mobile row bg-info p-1 m-0 container shadow rounded align-content-end border border-1' style={{ backgroundImage: 'linear-gradient(to right, #EC90DE, #ff9999, #6AF4D7)', color: 'black', padding: '20px' }}>

                            <div className='row row text-center align-self-center p-1 m-0 border border-1' style={{ backgroundImage: 'linear-gradient(to right, #6AF4D7, #ff9999, #6AF4D7)', color: 'black', padding: '20px' }}>
                                <div className='col-md-2 align-self-center'>Check In</div>
                                <div className='col-md-1 align-self-center '>Room No</div>
                                <div className='col-md-1 align-self-center text-end '>Rent</div>
                                <div className='col-md-1 align-self-center text-end '>Ex. Bed</div>
                                <div className='col-md-1 align-self-center text-end '>Bed Ch.</div>
                                <div className='col-md-1 align-self-center text-end '>Dis %</div>
                                <div className='col-md-1 align-self-center text-end '>@ Day</div>
                                <div className='col-md-1 align-self-center text-end '>St.Days</div>
                                <div className='col-md-1 align-self-center text-end '>GST %</div>
                                <div className='col-md-1 align-self-center text-end '>GST Amt</div>
                                <div className='col-md-1 align-self-center text-end'>Total</div>
                            </div>

                            {roomdetail.map((resp, index) => {
                                const dateDifference = Math.floor((new Date(checkoutdate) - new Date(resp.chdate)) / (1000 * 60 * 60 * 24));
                                const displayStday = dateDifference === 0 ? 1 : dateDifference;
                                var currRent = (((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100))
                                if (Number(GST_P_1) > Number(currRent)) {
                                    billgst = "0";
                                } else if (Number(GST_P_2) > Number(currRent)) {
                                    billgst = "5";
                                } else if (Number(GST_P_3) > Number(currRent)) {
                                    billgst = "12";
                                } else if (Number(GST_P_4) > Number(currRent)) {
                                    billgst = "18";
                                } else if (Number(GST_P_4) <= Number(currRent)) {
                                    billgst = "28";
                                }

                                bl_Tot_1 = bl_Tot_1 + Number(displayStday)
                                bl_RoomRent = bl_RoomRent + Number(((resp.exbed * resp.exbedch) + resp.rent) * displayStday)
                                bl_Disc = bl_Disc + Number((((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100) * displayStday)
                                bl_total = bl_total + Number((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * displayStday)
                                bl_gstTotal = bl_gstTotal + Number(((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * billgst / 100) * displayStday)
                                return (
                                    <div key={index} className='row text-center align-self-center p-1 m-0 bg-light border border-1'>
                                        <div className='col-md-2 align-self-center'>{resp.chdate ? new Date(resp.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</div>
                                        <div className='col-md-1 align-self-center'>{resp.roomnoview}</div>
                                        <div className='col-md-1 align-self-center text-end '>{parseFloat(resp.rent).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</div>
                                        <div className='col-md-1 align-self-center text-end '>{resp.exbed}</div>
                                        <div className='col-md-1 align-self-center text-end '>{parseFloat(resp.exbedch).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</div>
                                        <div className='col-md-1 align-self-center text-end '>{discountperc}</div>
                                        <div className='col-md-1 align-self-center text-end '>{parseFloat(((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</div>
                                        <div className='col-md-1 align-self-center text-end '>{displayStday}</div>
                                        <div className='col-md-1 align-self-center text-end '>{billgst}</div>
                                        <div className='col-md-1 align-self-center text-end '>{parseFloat(((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * billgst / 100) * displayStday).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</div>
                                        <div className='col-md-1 align-self-center text-end '>{parseFloat(((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * displayStday) + (((((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100)) * billgst / 100) * displayStday)).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
            {/* /// Check In  */}
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode' > Check-OUT [ New ]</span></h5>
                <div className='row'>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Mob No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='mobno' value={mobno} class="form-control" id="inputname" /> <br />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label">Guest Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gname' value={gname} class="form-control" id="inputname1" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label">Add 1</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add1' value={add1} class="form-control" id="inputname11" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label">Add 2</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add2' value={add2} class="form-control" id="inputname111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Document No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='documentno' value={documentno} class="form-control" id="inputname1111" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Select Document</label>
                        <input type="file" class="form-control" id="documentpath" />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Email</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="inputname11111" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">GST No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gstno' value={gstno} class="form-control" id="inputname111111" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Company Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='cname' value={cname} class="form-control" id="inputname1111111" /> <br />
                    </div>
                    {/*- Stay Details -------------------------------------*/}
                    <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Stay Detail</span></h5>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Check In Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='indate' value={indate} id="1inputname" /> <br />
                    </div>
                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room Type</label>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e); getroombyid(e.target.value); }} name='rtype' value={rtype} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {RoomTYpe.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rtname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room</label>
                        <select id="roomcode" onChange={(e) => { onChangevalue(e) }} onBlur={(e) => { getrent(e.target.value) }} name='rcode' value={rcode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {Room.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Rent</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='rent' value={rent} class="form-control" id="roomrent" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Extra Bed</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exbed' value={exbed} class="form-control" id="111inputname" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Bed Charge</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exbedch' value={exbedch} class="form-control" id="extrabadchrge" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Male</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mg' value={mg} class="form-control" id="11111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Female</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='fg' value={fg} class="form-control" id="111111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Child</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='cg' value={cg} class="form-control" id="2inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Stay Days</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='stday' value={stday} class="form-control" id="22inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Dis %</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='discount' value={discount} class="form-control" id="222inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Advance</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='advance' value={advance} class="form-control" id="2222inputname" /> <br />
                    </div>
                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Paid By</label>
                        <select id="acccode" onChange={(e) => { onChangevalue(e) }} name='acccode' value={acccode} class="form-select">
                            <option selected key={0} value={0}>Choose...</option>
                            {AccCode.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.accname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Remarks</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='remarks' value={remarks} class="form-control" id="12inputname" /> <br />
                    </div>

                </div>
                <div class="col-md-12 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-right-from-bracket"></i> Hotel Bill Details</h5>
                    </div>
                    <div className='col-md-3 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />

                    </div>

                    <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                        </span>
                        <span> <input type="date" id="blfromdate" name="blfromdate" onChange={(e) => { bldatechange(e); }} value={blfromdate} className="form-control" /></span>


                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="bltodate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                        </span>
                        <span>
                            <input type="date" id="bltodate" name="bltodate" onChange={(e) => { bldatechange(e); }} value={bltodate} className="form-control" />
                        </span>
                    </div>


                    <div style={{ height: "80vh", verticalAlign: 'middle', height: '100%' }} className='col-12 '>

                        <table className="table table-hover table-striped" >
                            <thead>
                                <tr style={{ height: '100%' }}>
                                    <th scope="col" className="hide-on-mobile">SNo</th>
                                    <th scope="col" className="f-size-on-mobile" >BlNo</th>
                                    <th scope="col" className="f-size-on-mobile">Bill <span className="hide-on-mobile" >Date</span>  <span className="detail-on-mobile"> Details</span> </th>
                                    <th scope="col" className="hide-on-mobile">Time</th>
                                    <th scope="col" className="hide-on-mobile">Room</th>
                                    <th scope="col" className="hide-on-mobile">Bill Amount</th>
                                    <th scope="col" className="hide-on-mobile">Guest Name</th>
                                    <th scope="col" className="hide-on-mobile">Guest Mob</th>
                                    <th scope="col" className="hide-on-mobile">Guest Add</th>
                                    <th scope="col" className="f-size-on-mobile align-self-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((res, x) => (
                                    <tr style={{ verticalAlign: 'middle', height: '100%' }}><th scope="row" className="hide-on-mobile">{x + 1}</th>
                                        <td className="f-size-on-mobile align-self-center text-center" style={{ wordWrap: "break-word" }}>{res.shopvno}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <span className="detail-on-mobile"><b> Bill Date : {res.bldate ? new Date(res.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b> </span>  <span className='hide-on-mobile'>{res.bldate ? new Date(res.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</span> <br />
                                            <div className="detail-on-mobile" style={{ fontSize: "12px", color: "blue" }}>
                                                Guest : {res.guestname} <br /> Mob : {res.guestmob} <br /> Room : {res.roomdescription},
                                                Stay Days : {res.bltotaldays} <br /> Total Rent : {res.blroomrent} <br />Disc : {res.discountperc}%, Amount : {res.discountpercamt} <br />
                                                Gst Amount : {res.blgstamt}  <br />
                                                <span style={{ color: "red" }}> <b>Bill Amount : {res.blamt} </b></span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.entrytime ? new Date(`2022-01-01T${res.entrytime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.roomdescription}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.blamt}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.guestname}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.guestmob}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.guestadd1}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>

                                            <Link onClick={(e) => handleCheckout(e, res.id, res.roomdescription)} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link>&nbsp;&nbsp;
                                            <Link onClick={(e) => { handleOpenModal(res.id, res.shopvno) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>&nbsp; <div className='show-on-mobile'><p></p></div>
                                            <Link className='btn btn-sm btn-outline-primary'><i class="fa-solid fa-print"></i> <span className="hide-on-mobile"> Print </span></Link> &nbsp;
                                            <Link onClick={(e) => { handleOpenModalCancel(res.id, res.shopvno) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-ban"></i>  <span className="hide-on-mobile"> Cancel </span></Link>&nbsp;


                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>



                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpenEdit} onRequestClose={handleCloseModalEdit} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Edit Bill ? <br /> Room No : {blno} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { billtotal(1); }}>Yes</button> <button className="no-button" onClick={handleNoClickEdit}>No</button></div></div>
            </Modal>


            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this Bill No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(blno) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>

            <Modal isOpen={isModalOpenCancel} onRequestClose={handleCloseModalCancel} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div clas><p className='text-bg-danger'>Are you sure you want to Cancel this Bill No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cancelBill(blno) }}>Yes</button> <button className="no-button" onClick={handleNoClickCancel}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default SaleBill
